import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import dp from '../assets/images/dp.jpg'
import XIcon from '@mui/icons-material/X';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      
      <div className="about-section">
        <div className="image-wrapper">
          <img src={dp} alt="Avatar" />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/bytecoder01" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/faseeh-ul-din-bilal-b44972324?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href="https://www.instagram.com/faseeh._01?igsh=NmlvcG5qMGMzN200" target="_blank" rel="noreferrer"><InstagramIcon/></a>
            <a href="https://x.com/FaseehBilal01" target="_blank" rel="noreferrer"><XIcon/></a>
          </div>
          <div className="name-title">
          <h1>Faseeh Bilal</h1>
          </div>
          <p>Full Stack Developer | Computer Engineer | AI Enthusiast</p>

          <div className="mobile_social_icons">
            <a href="https://github.com/bytecoder01" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/faseeh-ul-din-bilal-b44972324?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href="https://www.instagram.com/faseeh._01?igsh=NmlvcG5qMGMzN200" target="_blank" rel="noreferrer"><InstagramIcon/></a>
            <a href="https://x.com/FaseehBilal01" target="_blank" rel="noreferrer"><XIcon/></a>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Main;