import React, { useState } from "react"
import Button from '@mui/material/Button'; // Import Button from Material-UI
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import mock04 from '../assets/images/7.jpg';
import LockIcon from '@mui/icons-material/Lock';

import mock1 from '../assets/images/8.jpg';
import mock05 from '../assets/images/6.jpg';
import mock06 from '../assets/images/5.jpg';
import mock07 from '../assets/images/4.jpg';
import mock08 from '../assets/images/3.jpg';
import mock09 from '../assets/images/2.jpg';
import mock10 from '../assets/images/1.jpg';
import '../assets/styles/Project.scss';
import SendIcon from '@mui/icons-material/Send';

function Project() {
    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const handleClickOpen = (url:string) => {
        setVideoUrl(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setVideoUrl('');
    };
    return (
        <div className="projects-container" id="projects">
            <h1 className="projects-title">Projects</h1>
            <div className="projects-grid">
            <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/wVEuTNvPKGI?si=DaCuxgM4bXOjg_cz')} target="_blank" rel="noreferrer">
                        <img src={mock1} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                 
                        <h2>EasyVid AI</h2>
               
                    <p>
                    EasyVid AI is an innovative SaaS platform that enables users to generate engaging videos in seconds. With support for both vertical and horizontal formats, subtitles, and audio speech, users can input a topic and customize video length and media options, streamlining the creation process for both short (1080x1920) and long (1920x1080) videos. It serves a wide range of users, simplifying their content creation process.</p>
                  <div className="buttons-project-container">
                  <Button 
    className="project-button" 
    endIcon={<LockIcon />} 
   
    sx={{
        backgroundColor: '#5000CA', 
        color: 'white !important',
        '& .MuiButton-label': {
            color: 'white !important', 
        },
        '&:hover': {
            backgroundColor: 'white',
            color: 'black !important',
            '.MuiSvgIcon-root': {
                color: 'black',
            }
        }
    }}
>
    Confidential
</Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/wVEuTNvPKGI?si=DaCuxgM4bXOjg_cz')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>


                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/3MdBNBKmPYA?si=2sT4J7VndEQUeIu_')} target="_blank" rel="noreferrer">
                        <img src={mock10} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                   
                        <h2>AI SLIDE STUDIO</h2>
                 
                    <p>Created AI Slide Studio, an AI-driven presentation maker generating stunning slides in seconds. This SaaS software is attracting many users, allowing them to enter prompts for customizable presentations with options to adjust fonts, colors, and backgrounds. Supporting PowerPoint and Google Slides, AI Slide Studio empowers users to create professional presentations effortlessly.</p>
                  <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://www.aislidestudio.com', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/3MdBNBKmPYA?si=2sT4J7VndEQUeIu_')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/2LS1Frln6UY')} target="_blank" rel="noreferrer">
                        <img src={mock09} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                   
                        <h2>FitConnect</h2>
                   
                    <p>Developed FitConnect, a comprehensive social media platform for fitness enthusiasts. Using a full-stack setup with Express, MongoDB, React, and Redux. It features a dynamic timeline for sharing and liking posts, real-time chat, and user interactions.. Users can access their personal timelines and see updates from followed accounts, with secure JWT authentication ensuring a smooth and engaging experience.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://85020344.fitconnect.pages.dev/auth', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/2LS1Frln6UY')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/oQOBDG7dIN4')} target="_blank" rel="noreferrer">
                        <img src={mock08} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                   
                        <h2>FitConnect - Chat Platform</h2>
                  
                    <p>Created a real-time chat app for FitConnect with no latency, using Socket.io for instant messaging. Key features include online/offline status updates, real-time notifications, chat history, and emoji support. The app also offers direct messaging, message reactions, media sharing, and robust privacy controls for an engaging and secure communication experience.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://85020344.fitconnect.pages.dev/auth', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/oQOBDG7dIN4')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/Y-kA2NLX0Mg')} target="_blank" rel="noreferrer">
                        <img src={mock07} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                 
                        <h2>ShopVista Admin Dashboard</h2>
                
                    <p>Developed a custom admin dashboard for ShopVista with React, featuring real-time sales metrics, order tracking, customizable charts, and client engagement insights. Smooth animations via Framer Motion and efficient state management with React hooks provide a sleek, interactive user experience.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://shop-vista-dashboard.pages.dev/', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/Y-kA2NLX0Mg')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/oBLppPIbK_o')} target="_blank" rel="noreferrer">
                        <img src={mock06} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                   
                        <h2>YourToothDuchess</h2>
                  
                    <p>Built YourToothDuchess, a responsive dental clinic website using React.js with essential pages, smooth animations via Framer Motion, and advanced design techniques for a professional web presence.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://yourtoothduchessdentalclinic.pages.dev/', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/oBLppPIbK_o')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                            
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/-Qhz6Ya9KYI')}target="_blank" rel="noreferrer">
                        <img src={mock05} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                   
                        <h2>DeadlinePro</h2>
          
                    <p>Built DeadlinePro, a task planner app in React, featuring task categorization, real-time feedback, reminders, and analytics. The app enhances productivity with intuitive charts and effective time management tools.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://deadlinepro.pages.dev/', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/-Qhz6Ya9KYI')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                            
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>

                <div className="project">
                    <a onClick={() => handleClickOpen('https://www.youtube.com/embed/ZGxIQdF1QyU')} target="_blank" rel="noreferrer">
                        <img src={mock04} className="zoom" alt="thumbnail" width="100%" />
                    </a>
                    <a href="https://github.com/yujisatojr/multi-reg-analysis" target="_blank" rel="noreferrer">
                        <h2>Gold Factory</h2>
                    </a>
                    <p>Developed Gold Factory, a fully responsive Forex trading website using HTML5, CSS3, JavaScript, and Bootstrap. Featuring a sleek design with interactive elements, it showcases the company's expertise and services while enhancing user engagement and facilitating client inquiries.</p>
                    <div className="buttons-project-container">
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => window.open('https://9028c741.thegoldfactory.pages.dev/', '_blank')}
                        sx={{
                            backgroundColor: '#5000CA', 
                            color: 'white !important',
                            '& .MuiButton-label': {
                              color: 'white !important', 
                               },
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black!important',
                                '.MuiSvgIcon-root': {
                                    color: 'black',
                                }
                            }
                        }}
                    >
                        Visit Project
                    </Button>
                    <Button 
                        className="project-button" 
                        endIcon={<SendIcon />} 
                        onClick={() => handleClickOpen('https://www.youtube.com/embed/ZGxIQdF1QyU')}
                        sx={{
                            backgroundColor: 'white', 
                            color: 'black',
                           
                            '&:hover': {
                                backgroundColor: '#5000CA',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                }
                            }
                        }}
                    >
                         Watch Video
                    </Button>
                    </div>
                </div>
                <Dialog 
                    open={open} 
                    onClose={handleClose} 
                    maxWidth="md" 
                    fullWidth
                >
                    <DialogContent style={{ position: 'relative', padding: 0 }}>
                        <IconButton 
                            aria-label="close" 
                            onClick={handleClose} 
                            style={{ position: 'absolute', right: 8, top: 8, color: 'white', zIndex: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className="video-container">
                        <iframe
                            width="100%"
                            height="480"
                            src={videoUrl}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        </div>
                    </DialogContent>
                </Dialog>

            </div>
        </div>
    );
}

export default Project;
