import React from "react";
import '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss';

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1 className="history-title">Career & Education</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid white' }}
            date="2024 - Present"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Founder & CEO</h3>
            <h4 className="vertical-timeline-element-subtitle">EasyVid AI & AI Slide Studio</h4>
            <p>
            Founded and lead EasyVid AI and AI Slide Studio, two AI-powered SaaS platforms serving a wide range of users. Dedicated to improving digital storytelling with advanced AI technologies.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023 - Present"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Freelance Full Stack Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Istanbul, Turkey</h4>
            <p>
            Developed dynamic web applications and custom APIs, focusing on frontend and backend solutions. Worked with clients to define requirements and deliver tailored software that meets their business needs.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2022"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Project Coordinator and Technical Support</h3>
            <h4 className="vertical-timeline-element-subtitle">Orthodontics Align</h4>
            <p>
            Oversaw project management, resolved technical software issues, and identified solutions to enhance workflow efficiency while supporting the successful completion of projects.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2022 - 2026"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faGraduationCap} />}
          >
            <h3 className="vertical-timeline-element-title">Bachelor of Computer Engineering</h3>
            <h4 className="vertical-timeline-element-subtitle">İstanbul Okan Üniversitesi, Turkey</h4>
            <p>
            Currently in third year, I have a strong foundation in computer engineering and software development, including Data Structures and Algorithms. While my main expertise lies elsewhere, I also know C/C++ and Python, enhancing my skills through collaborative projects.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2018 - 2022"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faGraduationCap} />}
          >
            <h3 className="vertical-timeline-element-title">IGCSE O-Level and A-Level Qualifications</h3>
            <h4 className="vertical-timeline-element-subtitle">British Council, Cambridge International Examinations</h4>
            <p>
            Completed O-Levels (engineering subjects) and two additional subjects, followed by A-Levels (engineering subjects) and one additional subject. These qualifications provided a solid academic foundation for my computer engineering studies.
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;
