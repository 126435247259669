import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact} from '@fortawesome/free-brands-svg-icons';
import { faMicrochip, faServer} from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "React",
    "HTML5",
    "CSS3",
    "JavaScript (ES6+)",
    "Redux",
    "React Libraries",
    "Framer Motion",
    "API Integration",  
    "Responsive Design",  
    
];

const labelsSecond = [
    "Node",
    "Express",
    "MongoDB",
    "RESTful APIs",
    "Socket.IO",
    "JWT Auth",
    "NoSQL",
    "Git/GitHub",
    "API Integration",
    "Performance Optimization",
    
    
];

const labelsThird = [
    "OpenAI",
    "Generative AI",
    "Large Language Models",
    "AI Tools",
    "AI Integration",
    "SaaS Solutions",
    "Generative AI",
    "Large Language Models",
    
    
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1 className="expertise-title">Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faReact} size="3x"/>
                    <h3>Front-End Development</h3>
                    <p>
                    I have extensive experience in building front-end web applications with strong proficiency in React. I integrate additional technologies to create dynamic, user-friendly interfaces, consistently delivering high-quality results.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faServer} size="3x"/>
                    <h3>Back-End Development</h3>
                    <p>I am proficient in backend development, specializing in Node.js, Express, and NoSQL databases like MongoDB. I build robust, scalable server-side systems and apply a variety of tools to ensure optimal performance and efficienc</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faMicrochip} size="3x"/>
                    <h3>GenAI & LLM</h3>
                    <p>Stay relevant in the market by leveraging the latest AI models in your projects. I have professional experience building enterprise grade GenAI-enabled solutions to empower intelligent decision making.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;